@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,body {
  font-family: 'Montserrat', sans-serif;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 4vmin);
  color: white;
}

.App-link {
  color: #fecd00;
}

.u-color--warning {
  color: #fecd00;
}

