@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

html,body {
  font-family: 'Montserrat', sans-serif;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 4vmin);
  color: white;
}

.App-link {
  color: #fecd00;
}

.u-color--warning {
  color: #fecd00;
}
